@import '/src/assets/styles/variable';
.filter {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  span:first-child {
    color: $gray5;
    margin-top: 15px;
    text-wrap: nowrap;
  }
}

.clock {
  background: #adddff;
  border-radius: 4px;
  padding: 6px 8px 5px;
  font-weight: 600;
  text-wrap: nowrap;
}
