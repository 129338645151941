.globalContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.startsContainer {
  display: flex;
  justify-content: space-between;
  p {
    margin-top: 0.25rem;
    color: #272d37;
  }
}
.headContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}
.action {
  text-decoration: underline;
  color: #272d37;
  font-size: 11px;
  font-weight: 520;
  cursor: pointer;
  word-break: normal;
}

.rating {
  font-weight: 600;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  margin-top: 0.5rem;
}
