.background {
  background: #ffffff;
  border-radius: 8px;
  padding: 24px;
  padding-bottom: 0;
  display: flex;
  min-height: 80vh;
  height: 100%;
  flex-direction: column;
  box-sizing: border-box;
}

.descriptionBody {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  margin-bottom: 1rem;
}

.descriptionBodyTitle {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.link {
  color: #0494fd;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.noteBlock {
  position: relative;
  border-radius: 2px;
  margin-bottom: 20px;
  li {
    list-style: none;
  }
}

.roleplayHeader {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  gap: 1rem;
  & :global(.inputGroup) {
    margin-bottom: 0;
    margin-left: auto;
  }
  & :global(div) {
    flex-wrap: wrap;
  }
}
