@import 'assets/styles/_variable.scss';

.chatContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 90vh;
  width: 100%;
  box-sizing: border-box;
}
.messagesContainer {
  flex: 1;
  display: flex;
  flex-direction: column-reverse;
  gap: 10px;
  overflow-y: auto;
  background-color: #f3f5f8;
  margin-bottom: 1rem;
  box-sizing: border-box;
  max-height: 100%;
  padding: 1rem 13rem 0;
  @include xsmall {
    padding: 1rem 2rem 0;
  }
  @include small {
    padding: 1rem 7rem 0;
  }
  @include medium {
    padding: 1rem 10rem 0;
  }
}
.messageIA {
  align-self: flex-start;
  color: #272d37;
  word-wrap: break-word;
  box-sizing: border-box;
}
.messageUser {
  align-self: flex-end;
  padding: 14px;
  background-color: #0994fd;
  border-radius: 8px 8px 0 8px;
  max-width: 60%;
  word-wrap: break-word;
  box-sizing: border-box;
  color: white;
  @include xsmall {
    max-width: 70%;
  }
}

.loadMessageContainer {
  align-self: flex-start;
  margin-left: 13rem;
  background-color: #eceff1;
  border-radius: 8px 8px 8px 0px;
  margin-bottom: 1rem;
  @include xsmall {
    margin-left: 2rem;
  }
  @include small {
    margin-left: 7rem;
  }
  @include medium {
    margin-left: 10rem;
  }
}

.inputContainer {
  background-color: white;
  border-top: 1px solid #ddd;
  display: flex;
  align-items: center;
  padding: 8px;
  margin: 0 13rem 1rem 13rem;
  border-radius: 8px;
  @include xsmall {
    margin: 0 2rem 2rem 2rem;
  }
  @include small {
    margin: 0 7rem 1rem 7rem;
  }
  @include medium {
    margin: 0 10rem 1rem 10rem;
  }
}

.hidden {
  display: none;
}

.scrollButton {
  position: fixed;
  bottom: 13%;
  right: 50%;
  background-color: #fff;
  color: black;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s ease-in-out;

  &:hover {
    background-color: #0777c5;
    color: #ddd;
  }
  @include xsmall {
    left: 50%;
    bottom: 16.5%;
  }
}

.promptContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0px 0.875rem;
  padding: 0 13rem;
  margin-bottom: 2.5rem;
  @include xsmall {
    grid-template-rows: repeat(4, 1fr);
    grid-template-columns: 1fr;
    gap: 0.65rem 0px;
    padding: 0 2rem;
    margin-bottom: 0.3rem;
  }
  @include small {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 1rem;
    padding: 0 7rem;
    margin-bottom: 1.5rem;
  }
  @include medium {
    padding: 0 10rem;
  }
}
.tipContainer {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #cfd8dc;
  padding: 0.875rem 0.55rem 0.875rem 0.75rem;
}

.textContainer {
  margin-bottom: 1rem;
  @include xsmall {
    margin-bottom: 0px;
  }
}

.title {
  font-weight: $semiBoldFont;
  color: #193448;
  margin-bottom: 5px;
}

.text {
  font-size: $font12;
  font-weight: $regularFont;
  color: #6a8294;
  word-wrap: break-word;
  hyphens: auto;
}

.containerHtml {
  width: 100%;
  overflow-x: auto;
  box-sizing: border-box;
  div {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 8px;
    line-height: 1.75;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.75;
  }
  p br {
    display: block;
    content: '';
    margin-bottom: 8px;
  }

  h1 {
    line-height: 1.3;
    font-weight: 900;
    font-size: 2.1rem;
  }
  h2 {
    font-size: 25px;
    line-height: 1.3;
  }
  h3 {
    font-size: 21px;
  }
  ul,
  ol {
    margin-left: 10px;
    padding-left: 10px;
    line-height: 1.7;
  }
  ul {
    list-style-type: disc;
  }
  ul li {
    color: #333;
    margin-bottom: 5px;
    line-height: 1.7;
  }
  ol {
    list-style-type: decimal;
  }

  ol li {
    color: #222;
    margin-bottom: 5px;
  }
  //Nested lists
  ul ul,
  ol ol,
  ul ol,
  ol ul {
    margin-top: 5px;
    padding-left: 15px;
    line-height: 1.6;
  }
  //unordered lists.
  ul ul {
    list-style-type: circle;
  } // Second level
  ul ul ul {
    list-style-type: square;
  } // Third level
  //ordered lists
  ol ol {
    list-style-type: lower-roman;
  } //Second level
  ol ol ol {
    list-style-type: lower-alpha;
  } // Third level

  strong {
    font-weight: 700;
  }

  blockquote {
    padding: 0.8rem 0.5rem;
    p {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding: 0.7rem 0;
    }
  }
  hr {
    border: none;
    height: 1px;
    background: rgba(0, 0, 0, 0.25);
    margin-top: 0.5rem;
    margin-bottom: 0.2rem;
    display: block;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    overflow: hidden;
    border-radius: 8px;
    th,
    td {
      padding: 12px;
      text-align: left;
      border: 1px solid rgba(0, 0, 0, 0.1);
    }

    thead th {
      background-color: #e3e6e8;
    }
    :not(:has(thead)) th {
      background-color: #e3e6e8;
    }
    th:first-child {
      border-top-left-radius: 8px;
    }

    th:last-child {
      border-top-right-radius: 8px;
    }

    tr:last-child td:first-child {
      border-bottom-left-radius: 8px;
    }

    tr:last-child td:last-child {
      border-bottom-right-radius: 8px;
    }
  }
}
