@import 'variable';

body {
  word-break: break-word;
  @include fontStyle(
    $fontFamily: $font_OpenSans,
    $size: $font14,
    $weight: $regularFont,
    $color: $gray1,
    $lineHeight: $lineHeight21
  );

  * {
    margin: 0;
    padding: 0;
  }
  a {
    text-decoration: none;
    &:hover,
    &:active,
    &:focus,
    &:visited {
      text-decoration: none;
      cursor: pointer;
      outline: 0;
    }
  }

  button {
    border: none;
    background: none;
    cursor: pointer;
  }

  img {
    max-width: 100%;
  }

  .flexCentered {
    display: flex;
    display: -webkit-flex;
    flex-flow: wrap;
    -webkit-flex-flow: wrap;
    align-items: center;
    &:before,
    &:after {
      width: 0;
    }
  }

  .flexNotCentered {
    display: flex;
    display: -webkit-flex;
    flex-flow: wrap;
    -webkit-flex-flow: wrap;
    &:before,
    &:after {
      width: 0;
    }
  }

  .no-wrap {
    flex-wrap: nowrap;
  }

  .backgroundProp {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .hidden {
    display: none !important;
  }

  .heading1 {
    @include fontStyle(
      $fontFamily: $font_OpenSans,
      $size: $font32,
      $weight: $semiBoldFont,
      $color: $gray1,
      $lineHeight: $lineHeight48
    );
  }

  .heading2 {
    @include fontStyle(
      $fontFamily: $font_OpenSans,
      $size: $font24,
      $weight: $semiBoldFont,
      $color: $gray1,
      $lineHeight: $lineHeight36
    );
  }

  .heading3 {
    @include fontStyle(
      $fontFamily: $font_OpenSans,
      $size: $font20,
      $weight: $semiBoldFont,
      $color: $gray1,
      $lineHeight: $lineHeight30
    );
  }

  .heading4 {
    @include fontStyle(
      $fontFamily: $font_OpenSans,
      $size: $font18,
      $weight: $semiBoldFont,
      $color: $gray1,
      $lineHeight: $lineHeight27
    );
  }

  .heading5 {
    @include fontStyle(
      $fontFamily: $font_OpenSans,
      $size: $font16,
      $weight: $semiBoldFont,
      $color: $gray1,
      $lineHeight: $lineHeight24
    );
  }

  .heading5Regular {
    @include fontStyle(
      $fontFamily: $font_OpenSans,
      $size: $font16,
      $weight: $regularFont,
      $color: $gray1,
      $lineHeight: $lineHeight24
    );
  }

  .heading6 {
    @include fontStyle(
      $fontFamily: $font_OpenSans,
      $size: $font14,
      $weight: $semiBoldFont,
      $color: $gray3,
      $lineHeight: $lineHeight21
    );
  }

  .heading7Bold {
    @include fontStyle(
      $fontFamily: $font_OpenSans,
      $size: $font12,
      $weight: $semiBoldFont,
      $color: #455a64,
      $lineHeight: $lineHeight16
    );
  }

  .heading7 {
    @include fontStyle(
      $fontFamily: $font_OpenSans,
      $size: $font12,
      $weight: $regularFont,
      $color: #455a64,
      $lineHeight: $lineHeight16
    );
  }

  .paragraph {
    @include fontStyle(
      $fontFamily: $font_OpenSans,
      $size: $font14,
      $weight: $regularFont,
      $color: $gray3,
      $lineHeight: $lineHeight21
    );
    &.italic {
      font-style: italic;
    }
    .link {
      color: $blue;
      &:hover {
        text-decoration: underline;
      }
      &:visited {
        color: $blue;
      }
    }
  }

  .supportText {
    @include fontStyle(
      $fontFamily: $font_OpenSans,
      $size: $font12,
      $weight: $regularFont,
      $color: $gray3,
      $lineHeight: $lineHeight18
    );
    &.italic {
      font-style: italic;
    }
  }

  .supportTextSemibold {
    @include fontStyle(
      $fontFamily: $font_OpenSans,
      $size: $font12,
      $weight: $semiBoldFont,
      $color: $gray3,
      $lineHeight: $lineHeight18
    );
  }
  .supportTextSemi {
    @include fontStyle(
      $fontFamily: $font_OpenSans,
      $size: $font12,
      $weight: $semiBoldFont,
      $color: #193448,
      $lineHeight: $lineHeight18
    );
  }

  .label {
    @include fontStyle(
      $fontFamily: $font_OpenSans,
      $size: $font12,
      $weight: $regularFont,
      $color: $gray1,
      $lineHeight: $lineHeight18
    );
    cursor: default;
  }

  .avatar {
    @include fontStyle(
      $fontFamily: $font_OpenSans,
      $size: $font16,
      $weight: $semiBoldFont,
      $color: $white,
      $lineHeight: $lineHeight24
    );
    height: 32px;
    width: 32px;
    border-radius: 50%;
    object-fit: cover;
    background: $gray4;
    text-transform: capitalize;
    text-align: center;
    line-height: 32px;
  }

  .profilePic {
    height: 32px;
    width: 32px;
    object-fit: cover;
    border-radius: 50%;
  }
  .select {
    padding: 6.3px 20px 6.3px 10px;
    border-radius: 5px;
    border: none;
    margin-right: 12px;
    background-color: #0494fd;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Open Sans';
    color: white;
    cursor: pointer;
    overflow: hidden;
  }
  option {
    background-color: #ffffff;
    color: #193448;
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: 500;
  }
  .primaryBtn {
    @include fontStyle(
      $fontFamily: $font_OpenSans,
      $size: $font14,
      $weight: $semiBoldFont,
      $color: $white,
      $lineHeight: $lineHeight16
    );
    background: $blue;
    @include transitionForAll($time: 0.4s, $transProp: ease-out);
    padding: 10px 36px;
    outline: none;
    text-align: center;
    display: inline-block;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    text-transform: capitalize;
    &:hover,
    &:focus,
    &:active {
      @include transitionForAll($time: 0.3s, $transProp: ease-in);
      outline: none;
      background: $blue2;
    }
    &.disabledBtn {
      background: $gray6;
      cursor: not-allowed;
      pointer-events: none;
    }
    &.smallBtn {
      padding: 7px 18px;
      border: 1px solid transparent;
    }
    &.submitBtn {
      padding: 16px 36px;
      width: 100%;
      box-sizing: border-box;
    }
    &.deleteBtn {
      background-color: $color_F25B5B;
    }
  }

  .secondaryBtn {
    @include fontStyle(
      $fontFamily: $font_OpenSans,
      $size: $font14,
      $weight: $semiBoldFont,
      $color: $blue,
      $lineHeight: $lineHeight16
    );
    background: $white;
    @include transitionForAll($time: 0.4s, $transProp: ease-out);
    padding: 8px 36px;
    outline: none;
    text-align: center;
    display: inline-block;
    border: 1px solid $blue;
    cursor: pointer;
    border-radius: 5px;
    text-transform: capitalize;
    &:hover,
    &:focus,
    &:active {
      background: $blue4;
      color: $blue2;
      border: 1px solid $blue2;
      @include transitionForAll($time: 0.3s, $transProp: ease-in);
      outline: none;
    }
    &.smallBtn {
      padding: 7px 18px;
    }
    &.submitBtn {
      padding: 16px 36px;
      width: 100%;
    }
  }

  .tertiaryBtn {
    @include fontStyle(
      $fontFamily: $font_OpenSans,
      $size: $font14,
      $weight: $semiBoldFont,
      $color: $blue,
      $lineHeight: $lineHeight16
    );
    background: $blue4;
    @include transitionForAll($time: 0.4s, $transProp: ease-out);
    padding: 10px 16px;
    outline: none;
    text-align: center;
    display: inline-block;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    text-transform: capitalize;
    &:hover,
    &:focus,
    &:active {
      @include transitionForAll($time: 0.3s, $transProp: ease-in);
      outline: none;
      background: $blue3;
    }
    &.smallBtn {
      padding: 8px 18px;
    }
  }

  .errorBtn {
    @include fontStyle(
      $fontFamily: $font_OpenSans,
      $size: $font14,
      $weight: $semiBoldFont,
      $color: $red,
      $lineHeight: $lineHeight16
    );
    background: $lightRed;
    @include transitionForAll($time: 0.4s, $transProp: ease-out);
    padding: 10px 16px;
    outline: none;
    text-align: center;
    display: inline-block;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    text-transform: capitalize;
    &:hover,
    &:focus,
    &:active {
      @include transitionForAll($time: 0.3s, $transProp: ease-in);
      outline: none;
      background: $primaryRed;
    }
    &.smallBtn {
      padding: 8px 18px;
    }
  }

  .alignItems {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 30px;
  }

  .justifyItems {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
  }

  .helpText {
    @include fontStyle(
      $fontFamily: $font_OpenSans,
      $size: $font12,
      $weight: $regularFont,
      $color: $blue,
      $lineHeight: $lineHeight18
    );
    &:hover {
      cursor: pointer;
    }
  }

  .linkBtn {
    @include fontStyle(
      $fontFamily: $font_OpenSans,
      $size: $font14,
      $weight: $semiBoldFont,
      $color: $blue,
      $lineHeight: $lineHeight16
    );
    @include transitionForAll($time: 0.4s, $transProp: ease-out);
    text-transform: capitalize;
    &:hover,
    &:focus,
    &:active {
      color: $blue2;
      background: transparent;
      @include transitionForAll($time: 0.3s, $transProp: ease-in);
    }
  }

  .errorMsg {
    position: relative;
    @include fontStyle(
      $fontFamily: $font_OpenSans,
      $size: $font12,
      $weight: $regularFont,
      $color: $red,
      $lineHeight: $lineHeight18
    );
    padding-top: 4px;
    // padding-left: 20px;
    // &::before {
    //   content: '';
    //   background: url('error_icon.svg') no-repeat;
    //   width: 15px;
    //   height: 14px;
    //   position: absolute;
    //   background-size: contain;
    //   top: 5px;
    //   left: 2px;
    // }
  }

  .progressBar {
    progress {
      background-color: $gray7;
      color: $green;
      border-radius: 34px;
      overflow: hidden;
      height: 8px;
      vertical-align: middle;
      &.thick-progress {
        height: 24px;
      }
      &::-webkit-progress-bar {
        background-color: $gray7;
        width: 100%;
      }
      &::-webkit-progress-value {
        background-color: $green !important;
      }
      &::-moz-progress-bar {
        background-color: $green !important;
      }
      &.stepProgress {
        &::-webkit-progress-bar {
          background-color: $white;
        }
      }
      &.status1 {
        color: $green;
      }
      &.status3 {
        color: $yellow;
        &::-webkit-progress-value {
          background-color: $yellow !important;
        }
        &::-moz-progress-bar {
          background-color: $yellow !important;
        }
      }
      &.status2 {
        color: $orange;
        &::-webkit-progress-value {
          background-color: $orange !important;
        }
        &::-moz-progress-bar {
          background-color: $orange !important;
        }
      }
      &.error {
        color: $red;
        &::-webkit-progress-value {
          background-color: $red !important;
        }
        &::-moz-progress-bar {
          background-color: $red !important;
        }
      }
    }
    .progressValue {
      vertical-align: middle;
      margin-left: 10px;
      &.error {
        progress::-webkit-progress-value {
          background-color: $red !important;
        }
      }
      &.initialValue {
        color: $red !important;
      }
    }
    &.multiple {
      height: 24px;
      overflow: hidden;
      line-height: 0;
      background-color: $gray7;
      border-radius: 36px;
      > div {
        border-radius: 36px;
        padding: 0;
        justify-content: center;
        font-size: 13px;
        line-height: 18px;
        color: $gray1;
        font-weight: $semiBoldFont;
        position: relative;
        @media (min-width: 960px) {
          > span {
            position: absolute;
            width: max-content;
          }
        }
        &.status1Bg {
          background: $green;
          z-index: 998;
        }
        &.status2Bg {
          background: $color_FFCE97;
          z-index: 99;
          &::before {
            background: $color_FFCE97;
            content: '';
            position: absolute;
            height: 24px;
            width: 16px;
            left: -8px;
          }
        }
        &.status3Bg {
          background: $color_FFECBC;
          z-index: 9;
          &::before {
            background: $color_FFECBC;
            content: '';
            position: absolute;
            height: 24px;
            width: 16px;
            left: -8px;
          }
        }
      }
    }
  }

  .disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.6;
  }

  .flex-item {
    /* this */
    flex: 1 100px;
    /* is the same as */
    flex-grow: 1;
    flex-basis: 100px;
    /* and it leaves the flex-shrink property alone, which would be */
    flex-shrink: inherit; /* defaults to 1 */
  }

  .listUnstyled {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .listInline {
    list-style: none;
    .listInlineItem {
      display: inline-block;
      width: auto;
      .MuiTouchRipple-root {
        display: none;
      }
    }
  }

  .textCenter {
    text-align: center;
  }

  .textRight {
    text-align: right;
  }

  *:focus {
    outline: 0;
    outline: none;
  }

  .MuiFormLabel-asterisk {
    color: $gray1;
  }

  .link {
    text-decoration: none;
    color: inherit;
  }

  .inputGroup {
    outline: none;
    margin-bottom: 24px;
    // position: relative;
    .errorGroup {
      input,
      textarea,
      select {
        border: 1px solid $red;
        background: $lightRed;
        &:focus {
          background: transparent;
        }
      }
    }
    .disabledInputGroup {
      cursor: not-allowed;
      pointer-events: none;
      input,
      textarea,
      select {
        background: $gray7;
      }
    }
    .commentInputGroup {
      input {
        border: none;
        margin-bottom: 0;
        height: 32px;
        &:focus {
          border: none;
        }
      }
    }
    &.selectGroup {
      .inputGroup {
        margin-bottom: 0;
      }
      &.errorGroup {
        .reactSelect > div {
          border: 1px solid $red;
          background: $lightRed;
          &:focus {
            background: transparent;
          }
        }
      }
    }
    label {
      @include fontStyle(
        $fontFamily: $font_OpenSans,
        $size: $font12,
        $weight: $regularFont,
        $color: $gray1,
        $lineHeight: $lineHeight18
      );
      transform: none;
      position: relative;
      margin-bottom: 6px;
      display: block;
      .MuiFormLabel-asterisk {
        color: $red;
      }
    }
    input,
    select {
      height: 48px;
    }
    .MuiFormControl-root {
      margin: 0;
      width: 100%;
    }
    input,
    textarea,
    select {
      width: 100%;
      @include fontStyle(
        $fontFamily: $font_OpenSans,
        $size: $font14,
        $weight: $regularFont,
        $color: $gray2,
        $lineHeight: $lineHeight21
      );
      background: $white;
      border-radius: 4px;
      border: 1px solid $gray7;
      border-radius: 4px;
      padding: 10px 16px;
      box-sizing: border-box;
      //  &:hover{
      //   border-color:$color_00D2AD;
      //  }
      &:active,
      &:focus {
        border: 1px solid $blue;
      }
      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        @include fontStyle(
          $fontFamily: $font_OpenSans,
          $size: $font14,
          $weight: $regularFont,
          $color: $gray5,
          $lineHeight: $lineHeight21
        );
        opacity: 1;
        text-transform: none;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        @include fontStyle(
          $fontFamily: $font_OpenSans,
          $size: $font14,
          $weight: $regularFont,
          $color: $gray5,
          $lineHeight: $lineHeight21
        );
        text-transform: none;
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        @include fontStyle(
          $fontFamily: $font_OpenSans,
          $size: $font14,
          $weight: $regularFont,
          $color: $gray5,
          $lineHeight: $lineHeight21
        );
        text-transform: none;
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        @include fontStyle(
          $fontFamily: $font_OpenSans,
          $size: $font14,
          $weight: $regularFont,
          $color: $gray5,
          $lineHeight: $lineHeight21
        );
        text-transform: none;
      }
      .MuiTypography-body1 {
        background: none;
        padding-left: 0 !important;
        font-size: $font14;
      }
      &.Mui-disabled {
        border: none;
        background: $gray7;
        opacity: 0.7;
        pointer-events: none;
      }
      &:active,
      &:focus {
        outline: none;
      }
    }
    .MuiSelect-select {
      padding-left: 10px;
      background: $white;
      border-radius: 4px;
      padding: 13px 24px 12px 10px;
      border: 1px solid #e0e0e0;
      @include fontStyle(
        $fontFamily: $font_OpenSans,
        $size: $font14,
        $weight: $regularFont,
        $color: $gray2,
        $lineHeight: $lineHeight21
      );
    }
    .MuiFormLabel-root.Mui-focused {
      // color: $primaryRed;
      color: rgba(0, 0, 0, 0.9);
    }
    label + .MuiInput-formControl {
      margin-top: 0px;
    }
    .MuiOutlinedInput-notchedOutline {
      display: none;
    }
    .MuiInput-underline.Mui-disabled:before {
      border-bottom: none;
    }
    .MuiSelect-select.Mui-disabled {
      background: $white;
      opacity: 0.8;
      cursor: text;
      color: rgba(0, 0, 0, 0.6);
    }

    .MuiInput-underline:before {
      border-bottom: none;
    }
    .MuiInput-underline:after {
      border-bottom: none;
    }
    .MuiInputBase-multiline {
      padding: 0;
    }
    .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom: none;
    }
    .MuiInput-formControl {
      // margin-top: 0;
    }
    .selectFormControl {
      .MuiSelect-icon {
        right: 10px;
      }
    }
    // input[type='submit'] {
    //   @include fontStyle(
    //     $fontFamily: $font_Inter,
    //     $size: $font14,
    //     $weight: $semiBoldFont,
    //     $color: $white,
    //     $lineHeight: $lineHeight18
    //   );
    //   background: $primaryRed;
    //   @include transitionForAll($time: 0.4s, $transProp: ease-out);
    //   padding: 10px 16px;
    //   outline: none;
    //   text-align: center;
    //   display: inline-block;
    //   border: none;
    //   cursor: pointer;
    //   border-radius: 4px;
    //   text-transform: capitalize;
    // }

    .MuiCheckbox-colorPrimary.Mui-checked {
      color: $blue;
    }
    .MuiCheckbox-root {
      color: $blue;
    }

    .reactSelect {
      // margin-top: 8px;
      > div {
        cursor: pointer;
        border-color: $gray7;
        min-height: 48px;
        background: $white;
        // margin-right: 10px;
      }
      input {
        height: 26px;
        box-shadow: none;
      }
      .css-1fhf3k1-control {
        background: $white;
        opacity: 0.6;
        .css-g1d714-ValueContainer {
          .css-107lb6w-singleValue {
            cursor: text;
            color: rgba(0, 0, 0, 0.8);
            font-weight: $semiBoldFont;
          }
        }
      }
      .css-26l3qy-menu {
        z-index: 99999999;
        @include fontStyle(
          $fontFamily: $font_OpenSans,
          $size: $font14,
          $weight: $semiBoldFont,
          $color: $gray2,
          $lineHeight: $lineHeight20
        );
        box-shadow: none;
        border: none;
        > div {
          background: $white;
          box-shadow: 0px 4px 8px rgba(108, 108, 108, 0.12);
          border-radius: 0px 0px 4px 4px;
          border: 1px solid $gray7;
        }
      }
      .css-1pahdxg-control {
        // border-color: $primaryRed;
        box-shadow: 0px 0px 10px rgba(0, 210, 173, 0.36);
      }
      > div {
        // width: auto !important;
      }
      .css-g1d714-ValueContainer {
        padding: 0 8px;
        // height: 42px;
        .css-1uccc91-singleValue {
          @include fontStyle($weight: $semiBoldFont);
        }
        .css-1wa3eu0-placeholder {
          font-size: $font14;
          font-family: $font_OpenSans;
          color: $gray5;
        }
      }
      .css-xb97g8 {
        svg {
          height: 16px;
          fill: #c7d2e0;
        }
      }
      .css-xb97g8:hover {
        background: #f1f4f8;
        cursor: pointer;
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
      .css-12jo7m5 {
        img {
          display: none !important;
          opacity: 0 !important;
        }
        .contentBlk {
          margin-left: 40px !important;
          p {
            display: none !important;
            opacity: 0 !important;
          }
        }
      }
      .css-1wa3eu0-placeholder {
        @include fontStyle(
          $fontFamily: $font_OpenSans,
          $size: $font14,
          $weight: $regularFont,
          $color: $gray5,
          $lineHeight: $lineHeight21
        );
        opacity: 1;
        text-transform: none;
      }
    }
  }

  .MuiMenu-list {
    li {
      @include fontStyle(14px, $semiBoldFont, $gray2);
      background: $white;
    }
  }
  .MuiListItem-root.Mui-selected {
    // background: rgba(239, 67, 73, 0.1);
    // background: #f4f2ef;
    color: $gray1;
    &:hover {
      // background: #f4f2ef;
    }
  }
  .MuiListItem-button:hover {
    // background: #f4f2ef;
  }

  .textAreaGroup {
    margin-bottom: 16px;
    textarea {
      max-width: 100%;
      width: 100% !important;
      // height: 100% !important;
    }
  }

  .MuiRadio-root {
    color: $blue;
    font-weight: $semiBoldFont;
  }
  .MuiRadio-colorSecondary.Mui-checked {
    color: $blue;
    font-weight: $semiBoldFont;
  }
  .radioButton {
    .MuiTypography-root {
      @include fontStyle(
        $fontFamily: $font_OpenSans,
        $size: $font12,
        $weight: $semiBoldFont,
        $color: $gray3,
        $lineHeight: $lineHeight18
      );
    }
  }

  // .phoneNumberGroup {
  //   label {
  //     margin-bottom: 18px !important;
  //   }
  //   .PhoneInput {
  //     border: 1px solid $gray7;
  //     border-radius: 4px;
  //     padding: 0 10px;
  //     input {
  //       border: none;
  //       &:focus {
  //         box-shadow: none;
  //       }
  //     }
  //   }
  // }
  //

  //
  // .nameGroup {
  //   input {
  //     text-transform: capitalize;
  //   }
  // }

  .passwordGroup {
    position: relative;
    .passwordShow {
      position: absolute;
      top: 39px;
      right: 14px;
      z-index: 99;
      cursor: pointer;
      // color: $color_2F80ED;
      text-transform: uppercase;
    }
    input {
      padding-right: 55px;
    }
    .MuiTooltip-popper {
      // transform: translate3d(0, -136px, 0px) !important;
      transform: translate3d(198px, -46px, 0px) !important;
      right: 0 !important;
      left: auto !important;
    }
  }

  .checkboxGroup {
    margin-bottom: 16px;
    label {
      .MuiTypography-root {
        @include fontStyle(
          $fontFamily: $font_OpenSans,
          $size: $font12,
          $weight: $semiBoldFont,
          $color: $gray2,
          $lineHeight: $lineHeight18
        );
        position: relative;
        top: 1.5px;
      }
      .MuiButtonBase-root {
        padding: 4px;
      }
      input {
        height: 24px;
      }
      .MuiSvgIcon-root {
        height: 16px;
        width: 16px;
      }
    }
  }

  .MuiSelect-select:focus {
    background: $white;
  }

  .MuiTooltip-tooltip {
    // padding: 0 !important;
    // padding: 16px;
    // background: $gray2;
    // color: $white;
  }

  .MuiTooltip-popper {
    z-index: 99999 !important;
  }

  .moreMenu {
    z-index: 9999 !important;
    .MuiPaper-elevation8 {
      box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.06);
    }
  }

  .MuiPopover-root {
    z-index: 99999 !important;
  }

  .MuiContainer-maxWidthLg {
    max-width: 1170px;
  }

  // .progressSliderWrapper {
  //   .sliderLabel {
  //     span {
  //       color: $gray5;
  //       display: inline-block;
  //       &.minValue {
  //       }
  //       &.maxValue {
  //         float: right;
  //       }
  //     }
  //   }
  //   .inputGroup {
  //     margin-bottom: 0;
  //   }
  //   .MuiSlider-root {
  //     color: $gray40;
  //     height: 3px;
  //     margin-left: 5px;
  //     // margin-top: 8px;
  //     .MuiSlider-rail {
  //       height: 3px;
  //       border-radius: 16px;
  //     }
  //     .MuiSlider-track {
  //       height: 3px;
  //       border-radius: 16px;
  //       color: $primaryRed;
  //     }
  //     .MuiSlider-thumb {
  //       width: 16px;
  //       height: 16px;
  //       color: $white;
  //       border: 5px solid $white;
  //       border-radius: 50%;
  //       box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15),
  //         0px 2px 5px rgba(42, 42, 42, 0.15), 0px 1px 1px rgba(0, 0, 0, 0.12);
  //       margin-top: -7px;
  //     }
  //   }
  //   .MuiSlider-thumb.MuiSlider-active {
  //     // box-shadow: 0px 0px 0px 14px rgb(183 185 197 / 16%);
  //   }
  //   &.rangeSlider {
  //     .inputGroup {
  //       margin-bottom: 24px;
  //     }
  //   }
  // }
  //
  // .rangeInputBlk {
  //   border: 1px solid $gray40;
  //   border-radius: 4px;
  //   input {
  //     border: none;
  //     &:focus {
  //       box-shadow: none;
  //     }
  //   }
  //   ul {
  //     padding: 0;
  //     li {
  //       padding: 0;
  //       .currency {
  //         padding: 9px 12px 8px;
  //         position: relative;
  //         top: 7px;
  //         background: $gray40;
  //         border-radius: 4px 0 0 4px;
  //       }
  //       .inputGroup,
  //       .inputItem {
  //         margin: 0;
  //         input {
  //           border: none;
  //           &:focus {
  //             box-shadow: none;
  //           }
  //         }
  //       }
  //       &.currencyItem {
  //         width: 34px;
  //         height: 40px;
  //       }
  //       &.inputItem {
  //         width: calc(100% - 34px);
  //       }
  //       &.right {
  //         right: 3px;
  //         border-radius: 0 4px 4px 0;
  //         // position: relative;
  //         // top: 1px;
  //       }
  //       &.padded_right {
  //         right: 31px;
  //       }
  //     }
  //   }
  // }

  .tagItem {
    a {
      background: $gray2;
      border: 1px solid $gray7;
      border-radius: 4px;
      padding: 10px 20px 8px;
      cursor: default;
      span {
      }
      .removeIcon {
        cursor: pointer;
        margin-left: 14px;
      }
    }
  }

  .customRadioButtonList {
    padding: 0;
    li {
      padding: 0 22px 22px 0;
      a {
        display: inline-block;
      }
      &:last-child {
        padding: 0;
      }
    }
  }

  .customRadioButton {
    a {
      background: transparent;
      border: 1px solid $gray7;
      border-radius: 4px;
      padding: 10px 20px 8px;
      @include transitionForAll($time: 0.3s, $transProp: ease);
    }
    &.active,
    &:hover {
      a {
        // background: $lightAccent;
        border: 1px solid $blue;
        span {
          color: $blue;
        }
      }
    }
    &.multi {
      a {
        padding-right: 40px;
        position: relative;
        span,
        img {
          vertical-align: middle;
        }
        img {
          // margin-left: 16px;
          position: absolute;
          right: 15px;
          width: 11px;
          height: 11px;
          top: 15px;
        }
      }
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    // margin: 0;
  }

  input {
    &.search {
      background-image: url($imageBasePath + 'search_icon.svg');
      background-position: left 8px center;
      background-size: 18px;
      background-repeat: no-repeat;
      height: 30px;
      padding: 5px;
      padding-left: 32px;
    }
  }

  .switchWrapper {
    .MuiFormControlLabel-root {
      margin-right: 0;
      .MuiSwitch-thumb {
        height: 10px;
        width: 10px;
        position: relative;
        left: 5px;
        top: 2px;
      }
      .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
        background: $green;
        opacity: 1;
        border-radius: 14px;
      }
      .MuiSwitch-colorSecondary.Mui-checked {
        color: $white;
        .MuiSwitch-thumb {
        }
      }
      .MuiButtonBase-root {
        height: 16px;

        &:hover {
          background: none;
        }
      }
    }
  }
  .disabledBtn {
    background: $gray6;
    cursor: not-allowed;
    pointer-events: none;
  }
}

//mobile portrait view //
@media (min-width: 0px) and (max-width: 599px) {
  body {
  }
}
