@import '/src/assets/styles/variable';
.titleWrapper {
  font-size: 12px;
  .activeTab {
    font-weight: 600;
    padding-bottom: 0.5rem;
    border-bottom: solid 2px #0494fd;
  }
}

.newRepTrainingHeader {
  display: flex;
  gap: 2rem;
  margin: 0 1.5rem 0 1.5rem;
  flex-wrap: wrap;

  .firstContainer {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1.75rem;
    border-bottom: 1px solid #dbeaf4;
    margin-top: 1rem;
    margin-right: auto;

    .titleWrapper {
      width: fit-content;
      padding-bottom: 0.5rem;
      & div:first-child {
        display: flex;
        gap: 0.25rem;
      }
    }
    .tabs {
      width: fit-content;

      & :global(ul) {
        height: 100%;
        display: flex;
        align-items: end;
      }
      & :global(li) {
        display: flex;
        height: 100%;
      }
    }
  }
  .secondContainer {
    display: flex;
    flex-wrap: wrap;
    column-gap: 2rem;
    row-gap: 1rem;
    align-items: center;
    padding-right: 1rem;

    .readinessInfoWrapper {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      .value {
        color: #415766;
        font-size: 14px;
        font-weight: 600;
      }
    }
    .completedCountWrapper {
      display: flex;
      flex-direction: column;

      & :global(.progressBar) {
        margin-top: -4px;
      }
      & :global(.progressBar .progressValue) {
        display: none;
      }
    }
  }
}

.backButton {
  margin-right: -1rem;
  padding-right: 1rem;
}

.filter {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  span:first-child {
    color: $gray5;
    margin-top: 15px;
    text-wrap: nowrap;
  }
}

.successLoad {
  display: block;
  background-color: #2DC38D2A;
  color: #2DC38D;
  font-size: 14px;
  border: solid #2DC38D 1px;
  padding: 0.25rem 0.75rem;
  margin-bottom: 1rem;
  border-radius: 4px;
  .successLoadAli{
    display: flex;
    justify-content: space-between;
  }
  .successCross{
    cursor: pointer;
  }
}

.errorLoad {
  display: block;
  background-color: #E83A3A2a;
  color: #E83A3A;
  font-size: 14px;
  border: solid #E83A3A 1px;
  padding: 0.25rem 0.75rem;
  margin-bottom: 1rem;
  border-radius: 4px;
  .errorLoadAli{
    display: flex;
    justify-content: space-between;
  }
  .errorLoadCross{
    cursor: pointer;
  }
}
.bulkImport {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  .stepsImport {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.5rem;
  }
  .errorUnsupportedFile {
    color: #e83a3a;
    font-size: 12px;
    margin-bottom: -0.75rem;
  }
  .downloadTemplate {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 1rem;
  }
}

.actionButtons{
  display: flex;
  gap: 1rem;
}