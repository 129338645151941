.mainContainer {
  width: 100%;
  padding: 1rem;
  padding-bottom: 2rem;
  box-sizing: border-box;
  min-height: calc(100vh - 64px);

  .card {
    border-radius: 0.5rem;
    background: #fff;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100%;
    box-sizing: border-box;
    @media screen and (min-width: 1080px) {
      overflow-y: auto;
      max-height: calc(100dvh - 5rem);
      scrollbar-width: thin;
    }
  }
}

.header {
  .title {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: center;
  }
  .count {
    margin-left: 1.6rem;
  }
}

.videoContainer {
  display: grid;
  place-items: center;
  width: 100%;
  max-width: 100%;
  height: 100%;
  background: #e8ebed;
}

.scenarioHeader {
  color: #003256;
  display: flex;
  flex-wrap: wrap;
  gap: 1.4rem;

  .column,
  .row {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
  .column {
    flex-direction: column;
    align-items: start;
  }
  .bold {
    font-weight: 600;
    min-height: 2rem;
    font-size: 0.875rem;
  }
}

.feedback {
  margin-top: 0.5rem;
  padding: 1rem;
  border-radius: 0.25rem;
  border: 1px solid rgba(4, 148, 253, 0.7);
  background: rgba(4, 148, 253, 0.07);
  min-height: 100px;
  max-height: 190px;
  overflow: auto;
}

.rating {
  font-weight: 600;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  margin-top: 0.5rem;
}

.navigationButtons {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-left: auto;
  margin-top: auto;
}

.listAttempts {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-top: 0.5rem;
  p {
    padding: 0 0 0.5rem 0;
    cursor: pointer;
  }
  .selected {
    color: #000;
    border-bottom: 3px solid #0494fd;
  }
}
