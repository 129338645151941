@import 'assets/styles/_variable.scss';

.action {
  text-decoration: underline;
  color: #272d37;
  font-size: 11px;
  font-weight: 520;
}
.actionText {
  word-break: normal;
}
.message {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.iconChat {
  background-color: $blue;
  color: white;
}

.undoSnackbar {
  & :global(.MuiSnackbarContent-root) {
    min-width: 350px;
    background-color: #f0fffa;
    border: 1px solid $green;
    color: $green;
    box-shadow: none;
    padding: 1px 12px;
    flex-wrap: nowrap;
  }
}

.iconCheck {
  color: $green;
  background-color: #f0fffa;
  font-size: 1rem;
}

.container {
  display: flex;
  flex-wrap: nowrap;
  gap: 8px;
  align-items: center;
}
