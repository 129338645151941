.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  padding: 10px;
}

.loader span {
  width: 12px;
  height: 12px;
  background-color: #5f768c;
  border-radius: 50%;
  animation: bounce 0.6s infinite alternate;
}
.loader span:nth-child(2) {
  animation-delay: 0.2s;
}

.loader span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes bounce {
  0% {
    opacity: 1;
    background-color: #6c839a;
  }
  50% {
    opacity: 0.3;
    background-color: #aab8c8;
  }
  100% {
    opacity: 1;
    background-color: #cbd3d9;
  }
}
